.shadow-none-important {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.google-login-button {
  width: 100%;
  justify-content: center;
  border-radius: 0.5rem !important;
}
.google-login-button div,
.google-login-button span {
  padding: 0.42rem !important;
  font-size: 0.875rem !important;
}
