.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: flex;
}

.hover-target::before,
.hover-target::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid #f3f4f6;
}
.hover-target::after {
  border-top: 10px solid white;
  bottom: -19px;
}
