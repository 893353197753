.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm !important;
}

.react-datepicker__month-container {
  @apply flex flex-col !important;
}

.react-datepicker__month {
  @apply flex flex-col !important;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800 !important;
}

.react-datepicker__week {
  @apply flex justify-around !important;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs !important;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full !important;
}

.react-datepicker__navigation {
  @apply absolute top-2 !important;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200 !important;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200 !important;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded !important;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent !important;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300 !important;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200 !important;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-indigo-200 bg-opacity-30 !important;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-indigo-500 !important;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-indigo-500 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply bg-indigo-500 text-white !important;
}

.react-datepicker__day--range-start {
  @apply bg-indigo-500 text-white hover:text-gray-700 hover:bg-white !important;
}

.react-datepicker__day--range-end {
  @apply bg-indigo-500 text-white hover:text-gray-700 hover:bg-white !important;
}

.react-datepicker__header {
  @apply bg-white border-b-0 !important;
}

.react-datepicker__header button {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-indigo-500 !important;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent text-gray-700 !important;
}
